import React, { useEffect } from "react"
import { graphql } from "gatsby"
import Layout from "../../../components/Layout/Layout"
import Article from "../../../components/Article/Article"
import CallToAction from "../../../components/Article/CallToAction"
import Breadcrumbs from "../../../components/Breadcrumbs/Breadcrumbs"
import i18n from "i18next"

const SeoArticle = ({ data }) => {
  useEffect(() => {
    i18n.changeLanguage("pl")
  }, [])
  return (
    <Layout
      seo={{
        title: "Kod Google Remarketing. Jak działa na wyświetlanie się reklam w sieci?",
        description:
          "Dowiedz się, na jakiej zasadzie działa kod Google Remarketing, w jakich działaniach może skutecznie pomóc oraz jaki jest zakres jego możliwości.",
        schema: {
          "@context": "https://schema.org",
          "@type": "BreadcrumbList",
          itemListElement: [
            {
              "@type": "ListItem",
              position: 1,
              item: {
                "@id": "/",
                name: "Główna",
              },
            },
            {
              "@type": "ListItem",
              position: 2,
              item: {
                "@id": "/baza-wiedzy/artykuly/",
                name: "Baza wiedzy",
              },
            },
            {
              "@type": "ListItem",
              position: 3,
              item: {
                "@id": "/baza-wiedzy/artykul/co-daje-zainstalowanie-kodu-google-remarketing/",
                name: "Kod Google Remarketing. Jak działa na wyświetlanie się reklam w sieci?",
              },
            },
          ],
        },
      }}
    >
      <Breadcrumbs
        path={[
          { url: "/", text: "Główna" },
          {
            url: "/baza-wiedzy/artykuly/",
            text: "Baza wiedzy / Artykuły",
          },
          {
            url: "/baza-wiedzy/artykul/co-daje-zainstalowanie-kodu-google-remarketing",
            text: "Co daje zainstalowanie kodu Google Remarketing?",
          },
        ]}
      />
      <Article
        img={<img src={data.file.childImageSharp.fluid.src} alt={"Co daje zainstalowanie kodu Google Remarketing?"} />}
        alignTop={true}
      >
        <h1>Co daje zainstalowanie kodu Google Remarketing?</h1>
        <p>
          <strong>
            Remarketing to jeden z rodzajów internetowych kampanii reklamowych, który pozwala na powtarzanie przekazu
            reklamowego użytkownikom, którzy odwiedzili stronę internetową danej firmy lub danego produktu. Dowiedz się,
            jaki wpływ ma tego typu działanie na reklamy w sieci i co daje korzystanie z kodu Google Remarketing?
          </strong>
        </p>
        <h2>Jak działa Google Remarketing? </h2>
        <p>
          Google Remarketing pozwala na umieszczenie jednego tagu remarketingowego na wszystkich stronach danej witryny.
          Dzięki czemu możliwe jest uruchomienie kampanii remarketingowej na koncie Google Ads z dynamicznymi reklamami,
          czyli takimi, które są wynikiem przeglądanych przez użytkownika stron.
        </p>
        <p className="inserted-seo-link">
          Sprawdź,{" "}
          <a
            href="https://blog.wenet.pl/seo-pozycjonowanie/czym-jest-audyt-seo-dlaczego-jest-potrzebny-wlascicielom-stron-www/"
            target="_blank"
            rel="noopener, noreferrer"
          >
            czym jest audyt SEO
          </a>{" "}
          strony. Zdobądź niezbędne informacje!
        </p>
        <p>
          Na przykład: jeśli internauta wchodził na stronę z butami firmy X, to za sprawą plików cookies (ciasteczek)
          będą wyświetlały się w jego przeglądarce reklamy z butami firmy X.
        </p>
        <h2>W czym pomaga Google Remarketing?</h2>
        <p>
          Zainstalowanie kodu remarketingowego w Google Ads daje spore możliwości w kontekście kampanii reklamowych
          online. Podczas korzystania z tego rozwiązania, istnieje możliwość wyświetlania reklam na największych
          portalach w sieci, ponieważ nie miejsce, lecz zachowania użytkownika stanowią podstawę do wyświetlenia reklamy
          firmy lub produktu. Ma to duże przełożenie na ruch na stronie, a ze względu na precyzyjne określenie grupy
          docelowej również na wyższą konwersję.
        </p>
        <CallToAction
          to={"/"}
          text={"Wykonaj darmowy audyt strony i popraw jej widoczność w wyszukiwarce. Wykonaj teraz >>"}
        />
        <h2>Do czego jeszcze może służyć Google Remarketing?</h2>
        <p>
          Google Remarketing pozwala również na precyzyjne określanie grupy docelowej. Dzięki instalacji kodu istnieje
          możliwość m.in.:
        </p>
        <ul>
          <li>Wyświetlenia reklam u użytkowników, którzy byli na stronie, ale nie dokonali zakupu.</li>
          <li>
            Wyświetlić reklamę produktu X u użytkowników, którzy czytali artykuł na temat produktu X na stronie
            internetowej lub blogu firmy.
          </li>
        </ul>
        <p className="inserted-seo-link">
          Chcesz poprawić swoją pozycję w wyszukiwarce? Wykonaj <a href="/">audyt strony WWW</a>.
        </p>
        <p>
          Zastosowań remarketingu jest oczywiście więcej. W skrócie można powiedzieć, że chodzi przede wszystkim o
          zaangażowanie użytkowników, którzy wyrazili swoje zainteresowanie daną firmą lub produktem, lecz jeszcze nie
          zdecydowali się na zakupy. Działanie remarketingowe stanowi bardzo praktyczne rozwiązanie, bo dzięki niemu
          reklama trafia do osób, które są skłonne do skorzystania z przedstawionej oferty.{" "}
        </p>
      </Article>
    </Layout>
  )
}

export const query = graphql`
  {
    file(relativePath: { eq: "img/full/google-remarketing.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1100, jpegQuality: 90) {
          sizes
          src
        }
      }
    }
  }
`

export default SeoArticle
